import React, { Fragment } from 'react';
import { GlobalAuthContext } from 'contexts/GlobalContext';
import clsx from 'clsx';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { Hidden, Drawer, Paper } from '@material-ui/core';

import { connect } from 'react-redux';

import SidebarHeader from '../../layout-components/SidebarHeader';
import SidebarMenu from '../../layout-components/SidebarMenu';

import navSuperadmin from './navSuperadmin';
import navAccounting from './navAccounting';
import navClerck from './navClerck';
import navDataEntry from './navDataEntry';
import navManager from './navManager';
import navDataAnalysis from './navDataAnalysis';
import navSubadmin from './navSubadmin';
import navSupervisor from './navSupervisor'

import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';

const Sidebar = props => {
  const {
    setSidebarToggleMobile,
    sidebarToggleMobile,
    sidebarFixed,
    sidebarShadow
  } = props;
  const { state } = React.useContext(GlobalAuthContext);

  const closeDrawer = () => setSidebarToggleMobile(!sidebarToggleMobile);
  const handleMenuSide = cr_role => {
    switch (cr_role) {
      case 1:
        return navSuperadmin;
      case 2:
        return navSubadmin;
      case 3:
        return navAccounting;
      case 4:
        return navManager;
      case 5:
        return navDataEntry;
      case 6:
        return navClerck;
        case 7:
        return navSupervisor;
      default:
        return navDataEntry;
    }
  };
  let arrMenuSide = handleMenuSide(state.infouser.administration.role_id || 6);

  const sidebarMenuContent = (
    <div>
      {arrMenuSide.map(list => (
        <SidebarMenu
          component="div"
          key={list.label}
          pages={list.content}
          title={list.label}
        />
      ))}
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggleMobile}
          onClose={closeDrawer}
          variant="temporary"
          elevation={4}
          className="app-sidebar-wrapper-lg">
          <SidebarHeader />
          <PerfectScrollbar>{sidebarMenuContent}</PerfectScrollbar>
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Paper
          className={clsx('app-sidebar-wrapper', {
            'app-sidebar-wrapper-fixed': sidebarFixed
          })}
          square
          elevation={sidebarShadow ? 11 : 3}>
          <SidebarHeader />
          <div
            className={clsx({
              'app-sidebar-menu': sidebarFixed
            })}>
            <PerfectScrollbar options={{ wheelPropagation: false }}>
              {sidebarMenuContent}
            </PerfectScrollbar>
          </div>
        </Paper>
      </Hidden>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
